import React from 'react'

import Hero from '../Hero'

const Home = () => {
    return (
        <div className="fontKrub p-30 bg-gradient-to-r from-[#7F7070] to-[#380733] w-full h-full" >
            
            <Hero />
        </div>
    )
}

export default Home