import React from 'react'

const Thanks = () => {
    return (
        <div>
            <div class='md:text-[19px] text-[10px] md:mt-12 mt-7 font-light'>Thanks for looking around.</div>
            <div class='md:text-[19px] text-[10px] md:pt-6 pt-4 md:pb-2 font-light'>Stay in touch,</div>
            <a class='text-[#fffff] text-[11px] md:text-[20px]' href="mailto:imannfazal@gmail.com">imannfazal@gmail.com</a>
        </div>
    )
}

export default Thanks